<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('Select housekeeping')"
    style="width: 100%"
    :default-active-first-option="false"
    :not-found-content="null"
    :size="size"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d.id" :filter="d.name">
      {{ d.name }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select housekeeping": "Select housekeeping"
  },
  "ja": {
    "Select housekeeping": "清掃会社選択"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'InputHousekeeping',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    propertyId: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      id: undefined,
      data: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.id = nv ? parseInt(nv, 10) : undefined;
      },
    },
    propertyId: {
      immediate: true,
      handler() {
        this.data = [];
        this.handleSearch().then(() => {
          const exist = this.data.find((p) => p.id === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      const params = {
        propertyId: this.propertyId,
        relationship: 'Vendor',
        infinite: true,
      };

      if (value.length > 0) {
        params.name = value;
      }

      return this.$store.dispatch('partners/names', params).then((res) => {
        this.data = res.list;

        return Promise.resolve();
      });
    },
    delaySearch: debounce(function (value) {
      this.handleSearch(value);
    }, 600),
    filterOption(input, option) {
      return option.filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.id);
      this.$emit('update:value', this.id);
    },
  },
};
</script>

<style scoped></style>
